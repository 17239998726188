import { Button } from 'antd';
import { useRouter } from 'next/router';
import { rootPaths } from '../src/utils/constants/rootPaths';
import { layoutLang } from '../src/utils/language/layout';
import NotFoundIcon from '../src/assets/icons/NotFoundIcon';
export default function Custom404() {
  const {
    push,
    locale
  } = useRouter();
  const gotoMainPage = () => {
    push({
      pathname: rootPaths.INDEX
    });
  };
  return <div className="mb-[30px] flex w-full items-center justify-center" data-sentry-component="Custom404" data-sentry-source-file="404.tsx">
      <div className="wrapper">
        <div className="notfound">
          <div className="notfoundleft">
            <span>Ooops!</span>
            <h1>{layoutLang[locale || 'ru']?.['Ushbu sahifa topilmadi!']}</h1>
            <p>{layoutLang[locale || 'ru']?.['Sahifa mavjud emas']}</p>
            <Button onClick={gotoMainPage} data-sentry-element="Button" data-sentry-source-file="404.tsx">{layoutLang[locale || 'ru']?.['Bosh sahifaga qaytish']}</Button>
          </div>
          <NotFoundIcon data-sentry-element="NotFoundIcon" data-sentry-source-file="404.tsx" />
        </div>
      </div>
    </div>;
}