const NotFoundIcon = () => {
  return <svg width="710" height="421" viewBox="0 0 710 421" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="NotFoundIcon" data-sentry-source-file="NotFoundIcon.tsx">
      <path d="M304.759 392.17C291.055 374.97 283.29 349.277 281.428 315.054C279.601 281.007 284.555 254.715 296.291 236.141C308.062 217.567 325.981 207.628 350.085 206.288C374.189 204.984 393.093 212.914 406.761 230.114C420.465 247.313 428.23 272.936 430.057 306.948C431.884 340.994 426.93 367.322 415.194 386.002C403.458 404.682 385.503 414.656 361.435 415.995C337.331 417.299 318.462 409.369 304.759 392.17Z" fill="white" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M304.759 392.17C291.055 374.97 283.29 349.277 281.428 315.054C279.601 281.007 284.555 254.715 296.291 236.141C308.062 217.567 325.981 207.628 350.085 206.288C374.189 204.984 393.093 212.914 406.761 230.114C420.465 247.313 428.23 272.936 430.057 306.948C431.884 340.994 426.93 367.322 415.194 386.002C403.458 404.682 385.503 414.656 361.435 415.995C337.331 417.299 318.462 409.369 304.759 392.17Z" stroke="#00A389" strokeWidth="10" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M387.507 367.005C392.812 354.599 394.78 335.249 393.339 308.957C391.899 282.452 387.893 263.42 381.252 251.86C374.646 240.3 364.773 234.872 351.667 235.577C338.561 236.282 329.32 242.802 324.014 255.102C318.673 267.403 316.741 286.717 318.146 313.01C319.587 339.302 323.593 358.335 330.233 370.106C336.874 381.878 346.713 387.376 359.854 386.672C372.96 385.967 382.201 379.411 387.507 367.005Z" fill="#00A389" fillOpacity="0.3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M387.507 367.005C392.812 354.599 394.78 335.249 393.339 308.957C391.899 282.452 387.893 263.42 381.252 251.86C374.646 240.3 364.773 234.872 351.667 235.577C338.561 236.282 329.32 242.802 324.014 255.102C318.673 267.403 316.741 286.717 318.146 313.01C319.587 339.302 323.593 358.335 330.233 370.106C336.874 381.878 346.713 387.376 359.854 386.672C372.96 385.967 382.201 379.411 387.507 367.005Z" stroke="#00A389" strokeWidth="10" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M470.921 312.058H231.322V315.265H470.921V312.058Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M631.672 315.266H602.438C602.298 315.266 602.157 315.16 602.157 314.984V313.116C602.157 312.975 602.263 312.834 602.438 312.834H631.672C631.813 312.834 631.953 312.939 631.953 313.116V314.984C631.953 315.125 631.848 315.266 631.672 315.266Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M99.5233 315.265H70.2894C70.1488 315.265 70.0083 315.16 70.0083 314.983V313.115C70.0083 312.974 70.1137 312.833 70.2894 312.833H99.5233C99.6639 312.833 99.8044 312.939 99.8044 313.115V314.983C99.8044 315.124 99.699 315.265 99.5233 315.265Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M606.092 364.256H576.683C576.577 364.256 576.507 364.185 576.507 364.079V362.846C576.507 362.74 576.577 362.67 576.683 362.67H606.092C606.198 362.67 606.268 362.74 606.268 362.846V364.079C606.268 364.185 606.198 364.256 606.092 364.256Z" fill="#C4CEFE" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M113.86 107.25C119.446 107.25 124.295 103.197 125.033 97.6284C125.174 96.6416 125.244 95.6194 125.244 94.5973C125.244 82.2616 115.265 72.2169 102.932 72.2169C102.44 72.2169 101.913 72.2521 101.421 72.2873C100.402 60.0926 90.2475 50.5413 77.809 50.5413C64.7381 50.5413 54.1268 61.1852 54.1268 74.2963C54.1268 74.8955 54.1619 75.4594 54.197 76.0233C50.4725 73.9439 46.1507 72.816 41.5477 73.0275C29.6363 73.5914 19.8683 83.1075 19.025 95.0555C19.025 95.1613 19.025 95.2318 18.9898 95.3375C18.6033 101.823 23.8387 107.25 30.3039 107.25H113.86Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M696.71 162.091C714.349 162.091 715.332 135.481 697.694 134.6C697.202 134.565 696.745 134.565 696.253 134.565C693.126 134.565 690.069 135.058 687.258 135.975C684.904 121.56 672.43 110.563 657.392 110.563C642.037 110.563 629.352 122.053 627.385 136.926C623.73 135.164 619.655 134.177 615.333 134.177C612.908 134.177 610.589 134.495 608.34 135.058C592.915 139.041 596.148 162.056 612.065 162.056H696.71V162.091Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M389.326 77.7819C406.965 77.7819 407.949 51.172 390.31 50.2909C389.818 50.2557 389.361 50.2557 388.869 50.2557C385.742 50.2557 382.685 50.7491 379.874 51.6655C377.52 37.2503 365.046 26.2539 350.008 26.2539C334.653 26.2539 321.969 37.7437 320.001 52.6171C316.347 50.8548 312.271 49.868 307.949 49.868C305.524 49.868 303.205 50.1852 300.957 50.7491C285.532 54.7318 288.764 77.7467 304.681 77.7467H389.326V77.7819Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M223.753 51.528C241.392 51.528 242.375 24.9181 224.737 24.037C224.245 24.0018 223.788 24.0018 223.296 24.0018C220.169 24.0018 217.112 24.4952 214.301 25.4115C211.947 10.9964 199.473 0 184.435 0C169.08 0 156.395 11.4898 154.428 26.3632C150.773 24.6009 146.698 23.6141 142.376 23.6141C139.951 23.6141 137.632 23.9313 135.383 24.4952C119.958 28.4779 123.191 51.4928 139.108 51.4928H223.753V51.528Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M542.881 51.4928C560.52 51.4928 561.468 24.8829 543.865 24.037C543.373 24.0018 542.916 24.0018 542.424 24.0018C539.297 24.0018 536.24 24.4952 533.429 25.4116C531.04 10.9964 518.601 0 503.563 0C488.208 0 475.523 11.4898 473.591 26.3632C469.937 24.6009 465.861 23.6141 461.539 23.6141C459.114 23.6141 456.795 23.9313 454.582 24.4952C439.157 28.4779 442.389 51.4928 458.306 51.4928H542.881Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M557.882 111.826C558.237 98.6533 553.116 87.8275 546.443 87.646C539.77 87.4644 534.072 97.9958 533.717 111.169C533.362 124.341 538.484 135.167 545.156 135.348C551.829 135.53 557.527 124.999 557.882 111.826Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M125.647 175.811C125.931 165.285 121.962 156.637 116.783 156.496C111.604 156.356 107.175 164.775 106.891 175.301C106.608 185.828 110.576 194.475 115.755 194.616C120.934 194.757 125.363 186.338 125.647 175.811Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M211.036 117.084C211.32 106.558 207.352 97.9101 202.173 97.7694C196.993 97.6287 192.564 106.048 192.28 116.574C191.996 127.101 195.964 135.748 201.143 135.889C206.323 136.03 210.752 127.61 211.036 117.084Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M20.4042 150.983C20.8014 136.254 16.6574 124.192 11.1484 124.042C5.63945 123.892 0.851595 135.711 0.454449 150.44C0.0573023 165.169 4.20126 177.231 9.71023 177.381C15.2192 177.531 20.0071 165.712 20.4042 150.983Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M668.21 224.27C668.45 215.359 662.151 207.958 654.14 207.74C646.128 207.522 639.439 214.569 639.199 223.481C638.958 232.392 645.258 239.793 653.269 240.011C661.281 240.229 667.97 233.182 668.21 224.27Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M338.376 140.899C338.616 131.987 332.317 124.586 324.306 124.368C316.294 124.15 309.605 131.198 309.365 140.109C309.124 149.021 315.424 156.422 323.435 156.64C331.447 156.858 338.136 149.81 338.376 140.899Z" fill="#CFF4E6" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M620.148 309.309L597.625 313.856L606.69 359.04L558.447 368.767L549.382 323.548L461.013 341.347L453.985 306.278L507.991 167.907L566.037 156.205L589.262 272.09L611.785 267.544L620.148 309.309ZM528.44 218.976L502.896 289.537L541.019 281.853L528.44 218.976Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M248.434 332.676L225.771 328.87L218.216 374.336L169.692 366.23L177.246 320.764L88.3149 305.926L94.1828 270.645L193.691 160.576L252.088 170.339L232.728 286.929L255.391 290.735L248.434 332.676ZM194.71 215.558L145.869 272.408L184.239 278.822L194.71 215.558Z" fill="#00A389" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M799.802 315.266H-30.8696V421H799.802V315.266Z" fill="white" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M108.729 366.476H79.3197C79.2143 366.476 79.144 366.406 79.144 366.3V365.066C79.144 364.961 79.2143 364.89 79.3197 364.89H108.729C108.835 364.89 108.905 364.961 108.905 365.066V366.3C108.905 366.37 108.835 366.476 108.729 366.476Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M189.509 366.476H160.1C159.994 366.476 159.924 366.406 159.924 366.3V365.067C159.924 364.961 159.994 364.89 160.1 364.89H189.509C189.615 364.89 189.685 364.961 189.685 365.067V366.3C189.685 366.371 189.579 366.476 189.509 366.476Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M263.016 347.444H233.606C233.501 347.444 233.431 347.373 233.431 347.268V346.034C233.431 345.928 233.501 345.858 233.606 345.858H263.016C263.121 345.858 263.192 345.928 263.192 346.034V347.268C263.192 347.338 263.086 347.444 263.016 347.444Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M304.793 368.485H275.384C275.278 368.485 275.208 368.415 275.208 368.309V367.075C275.208 366.97 275.278 366.899 275.384 366.899H304.793C304.899 366.899 304.969 366.97 304.969 367.075V368.309C304.969 368.379 304.899 368.485 304.793 368.485Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M407.955 368.485H378.546C378.44 368.485 378.37 368.415 378.37 368.309V367.076C378.37 366.97 378.44 366.899 378.546 366.899H407.955C408.061 366.899 408.131 366.97 408.131 367.076V368.309C408.131 368.38 408.061 368.485 407.955 368.485Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M523.345 367.287H493.935C493.83 367.287 493.76 367.216 493.76 367.111V365.877C493.76 365.771 493.83 365.701 493.935 365.701H523.345C523.45 365.701 523.521 365.771 523.521 365.877V367.111C523.521 367.216 523.45 367.287 523.345 367.287Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M156.761 344.342H127.351C127.246 344.342 127.176 344.272 127.176 344.166V342.933C127.176 342.827 127.246 342.756 127.351 342.756H156.761C156.866 342.756 156.937 342.827 156.937 342.933V344.166C156.937 344.272 156.866 344.342 156.761 344.342Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M362.137 343.567H332.727C332.622 343.567 332.552 343.496 332.552 343.391V342.157C332.552 342.051 332.622 341.981 332.727 341.981H362.137C362.242 341.981 362.313 342.051 362.313 342.157V343.391C362.313 343.496 362.242 343.567 362.137 343.567Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M571.376 343.426H541.967C541.861 343.426 541.791 343.355 541.791 343.25V342.016C541.791 341.91 541.861 341.84 541.967 341.84H571.376C571.482 341.84 571.552 341.91 571.552 342.016V343.25C571.552 343.355 571.482 343.426 571.376 343.426Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
      <path d="M468.391 335.989H438.981C438.876 335.989 438.806 335.919 438.806 335.813V334.58C438.806 334.474 438.876 334.403 438.981 334.403H468.391C468.496 334.403 468.567 334.474 468.567 334.58V335.813C468.567 335.919 468.496 335.989 468.391 335.989Z" fill="#A9EFD3" data-sentry-element="path" data-sentry-source-file="NotFoundIcon.tsx" />
    </svg>;
};
export default NotFoundIcon;